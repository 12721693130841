import React from "react";
import AddBlogs from "./AddBlogs";
import { useEffect, useState } from "react";
import { getBlogById } from "./Adminclient";
import { useParams } from "react-router-dom";

const UpdateBlogs = () => {
    const { blog_id } = useParams();
    const [blogDetails, setBlogDetails] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (blog_id) {
            getBlogById(blog_id, setBlogDetails, setTagsList, setShowError);
        }
    }, [blog_id]);
    return (
        <div>
            {showError ? <div>Error fetching Blog</div> : ""}
            {!showError && (
                <AddBlogs blog_id={blog_id} blogDetails={blogDetails} tagsList={tagsList} />
            )}
        </div>
    );
};

export default UpdateBlogs;
