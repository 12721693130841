import Rating from "@mui/material/Rating";
import ArrowDown from "../../resources/images/ArrowDown.svg";
const UpgradeCareer = () => {
    return (
        <div className="bg-[#444BAB] text-[#ffffff] p-[1vw] rounded-[1vw] flex flex-col">
            <p className="font-PoppinsSemiBold text-[1.3vw]">Upgrade your tech Career</p>
            <p className="font-PoppinsRegular font-extralight text-[0.8vw] py-[0.5vw]">
                Learn from Top Experienced Tech Professionals and Grow Fast
            </p>
            <div className="flex items-center">
                <Rating
                    sx={{ color: "#FFC728" }}
                    name="half-rating-read"
                    defaultValue={5}
                    precision={0.2}
                    readOnly
                    size={window > 600 ? "small" : "medium"}
                />
                <p>4.5/5</p>
            </div>
            <p className="py-[0.5vw]">from 1000+ reviews</p>
            <div
                className="flex items-center justify-center bg-[#00F0FF] px-[1vw] py-[0.5vw] text-[#191D23] rounded-[0.75vw]"
                onClick={() => {
                    window.open(
                        "https://renaissance.programmingpathshala.com/crack-coding-interviews"
                    );
                }}
            >
                <p className="font-PoppinsMedium">Explore Courses</p>
                <img src={ArrowDown} alt="" />
            </div>
        </div>
    );
};

export default UpgradeCareer;
