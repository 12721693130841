import { useEffect, useState } from "react";
import { getBlogById } from "../client";
import { useLocation, useParams } from "react-router-dom";
import BlogContent from "./BlogContent";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";

const BlogDetails = () => {
    const { blog_id, blog_url } = useParams();
    const query = new URLSearchParams(useLocation().search);
    // const { mostViewedBlogs } = useBlogsContext();
    // const { categoryTag } = useBlogsContext();
    const [mostViewedBlogDetails, setmostViewedBlogDetails] = useState([]);
    const [blogPageDetailsTag, setBlogPageDetailsTag] = useState([]);
    const [categoryListdata, setCategoryListData] = useState([]);
    const [count, setCount] = useState(0);
    const [liked, setLiked] = useState(0);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [tagListLoading, setTagListLoading] = useState(true);
    const [recentBlogsLoading, setRecentBlogsLoading] = useState(true);

    useEffect(() => {
        if (blog_id !== undefined) {
            getBlogById(
                setmostViewedBlogDetails,
                blog_id,
                setBlogPageDetailsTag,
                setCount,
                setLiked,
                setIsLoading
            );
        }
    }, [blog_id]);

    return (
        <div>
            <BlogContent
                banner_image_url={mostViewedBlogDetails.banner_image_url}
                content={mostViewedBlogDetails.content}
                author={mostViewedBlogDetails.author}
                date_updated={mostViewedBlogDetails.date_updated}
                read_time_min={mostViewedBlogDetails.read_time_min}
                title={mostViewedBlogDetails.title}
                author_avatar_url={mostViewedBlogDetails.author_avatar_url}
                wrapper_class=""
                youtubeLink={mostViewedBlogDetails.youtube_link}
                blog_url={blog_url}
                blog_id={blog_id}
            />
            <ExploreBlogs />
        </div>
    );
};

export default BlogDetails;
