import axios from "axios";
import Cookies from "universal-cookie";

let postNewsLetterLeads = (email, setSnackBarOpen, setSnackbarErrorMessage) => {
    const cookies = new Cookies();

    let data = {
        email: email,
    };
    axios
        .post(`${process.env.REACT_APP_MISCELLANEOUS_URL}/postNewsLetterDetails`, data, {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            if (response && response.status === 200) {
                setSnackBarOpen(true);
                setSnackbarErrorMessage("Succesfully Subscribed to the newsletter");
            }
        })
        .catch((error) => {
            setSnackBarOpen(true);
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errorMessage &&
                error.response.data.errorMessage === "User Already subscribed"
            ) {
                setSnackbarErrorMessage("User Already subscribed");
            } else {
                setSnackbarErrorMessage("Something went wrong");
            }
        });
};

export { postNewsLetterLeads };
