import { getBlogsByCategory, searchBlogs, getRecentBlogs } from "../client";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import { CircularProgress } from "@mui/material";
import BlogCard from "./BlogCard";
import { Buffer } from "buffer";
import NewsLetter from "../NewsLetter/NewsLetter";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import { CATEGORIES_FIRST_ROW, CATEGORIES_SECOND_ROW, TAG_LIST } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import searchIcon from "../../resources/images/searchIcon.png";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";

const BlogsHomePage = () => {
    const navigate = useNavigate();
    const [blogs, setblogs] = useState(new Map());
    const { showError, setShowError, isAdmin } = useGlobalContext();
    const [categoryList, setCategoryList] = useState(TAG_LIST);
    const [category, setCategory] = useState("All");
    const [searchTerm, setSearchTerm] = useState();
    const [searchResults, setSearchResult] = useState();
    const [bannerBlogs, setBannerBlogs] = useState([]);

    const getAllCategories = async () => {
        setCategoryList(TAG_LIST);
        const recentBlogs = await getRecentBlogs(setShowError, showError);
        recentBlogs && recentBlogs.sort((a, b) => a.date_created - b.date_created);
        setBannerBlogs(recentBlogs.slice(0, 2));
        const recentBlogsMap = new Map();
        recentBlogs &&
            recentBlogs.map((recentBlogData) => {
                if (!recentBlogsMap.has(recentBlogData.tag_name)) {
                    recentBlogsMap.set(recentBlogData.tag_name, new Array());
                }
                recentBlogsMap.get(recentBlogData.tag_name).push(recentBlogData);
            });
        setblogs(recentBlogsMap);
    };

    const getSelectedCategory = async () => {
        const categoryData = [
            {
                tag_name: category,
            },
        ];
        setCategoryList(categoryData);
        const blog = await getBlogsByCategory(category, 100, setShowError, showError);
        setblogs(new Map(blogs.set(category, blog)));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        searchBlogs(searchTerm, setSearchResult);
    };
    useEffect(() => {
        if (category === "All") {
            getAllCategories();
        } else {
            getSelectedCategory();
        }
    }, [category]);

    const getSearchInput = () => {
        return (
            <form
                onSubmit={handleSubmit}
                className="relative flex flex-col justify-center items-center max-sm:px-[4vw]"
            >
                <input
                    className="border border-[#D0D5DD] focus:outline-none placeholder:pl-[2.2vw] rounded-[6px] text-[1vw] text-[#191D23] p-[0.5vw] w-[30vw] bg-[#F7F8F9] font-PoppinsRegular font-normal max-sm:w-full max-sm:p-[2vw] max-sm:text-[3vw] max-sm:placeholder:pl-[6vw]"
                    type="text"
                    placeholder="What are you looking for?"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm ? (
                    <></>
                ) : (
                    <img
                        className="w-[1.1vw] h-[1.1vw] absolute left-[35.5vw] max-sm:w-[4vw] max-sm:h-[4vw] max-sm:left-[6vw]"
                        src={searchIcon}
                        alt=""
                    />
                )}
            </form>
        );
    };

    const handleCategoryChange = (category_name) => {
        setCategory(category_name);
    };
    const getCategories = () => {
        return (
            <div className="py-[2vw]">
                <div className="flex flex-wrap justify-center items-center py-[0.5vw] max-sm:mx-[2vw]">
                    {CATEGORIES_FIRST_ROW.map((first_row_category) => (
                        <div
                            className={
                                first_row_category.tag_name === category
                                    ? "text-[#191D23] font-PoppinsMedium text-[1vw]  mx-[1vw] bg-[#E7EAEE] px-[2vw] py-[0.4vw] rounded-[10px] cursor-pointer max-sm:text-[3vw]  max-sm:px-[3vw] max-sm:py-[1.5vw] max-sm:my-[0.5vw]"
                                    : "text-[#191D23] font-PoppinsMedium text-[1vw]  mx-[1vw] hover:bg-[#E7EAEE] px-[2vw] py-[0.4vw] rounded-[10px] cursor-pointer max-sm:text-[3vw] max-sm:px-[3vw] max-sm:py-[1.5vw] max-sm:my-[0.5vw]"
                            }
                            onClick={() => handleCategoryChange(first_row_category.tag_name)}
                        >
                            {first_row_category.tag_name}
                        </div>
                    ))}
                </div>

                <div className="flex flex-wrap justify-center items-center py-[0.5vw] max-sm:mx-[5vw]">
                    {CATEGORIES_SECOND_ROW.map((second_row_category) => (
                        <div
                            className={
                                second_row_category.tag_name === category
                                    ? "text-[#191D23] font-PoppinsMedium text-[1vw]  mx-[1vw] bg-[#E7EAEE] px-[2vw] py-[0.4vw]  rounded-[10px] cursor-pointer max-sm:text-[3vw] max-sm:mx-[3vw] max-sm:py-[1.5vw] max-sm:my-[0.5vw]"
                                    : "text-[#191D23] font-PoppinsMedium text-[1vw]  mx-[1vw] hover:bg-[#E7EAEE] px-[2vw] py-[0.4vw] rounded-[10px] cursor-pointer max-sm:text-[3vw] max-sm:mx-[3vw] max-sm:py-[1.5vw] max-sm:my-[0.5vw] "
                            }
                            onClick={() => handleCategoryChange(second_row_category.tag_name)}
                        >
                            {second_row_category.tag_name}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const getAddBlogButton = () => {
        return (
            <div
                className="absolute left-[88vw] top-[5vw]"
                onClick={() => {
                    navigate(`/add-blog`);
                }}
            >
                <button className="bg-[#444bab] text-[1.1vw] text-white p-[1vw] border-none rounded-[1vw]">
                    Add Blog
                </button>
            </div>
        );
    };
    const getLatestBlogs = () => {
        return (
            <div className="flex max-sm:flex-col">
                {bannerBlogs &&
                    bannerBlogs.map((bannerBlog, index) => (
                        <div className="w-[50%] max-sm:w-[100%] max-sm:py-[2vw]">
                            <BlogCard key={index} blog={bannerBlog} blog_type={"latest"} />
                        </div>
                    ))}
            </div>
        );
    };
    return (
        <div className="bg-[#F7F8F9]">
            <div className="flex flex-col relative ">
                <p className="text-[2.3vw] font-bold text-center py-4 pt-[4vw] max-sm:text-[6vw] max-sm:px-[4vw]">
                    Blogs by Programming Pathshala{" "}
                </p>
                {isAdmin && getAddBlogButton()}
            </div>

            {getSearchInput()}
            {getCategories()}

            <div>
                {searchResults ? (
                    <div className="flex flex-col  py-4 px-[8vw]">
                        {" "}
                        <p className="font-PoppinsSemiBold text-center py-[2vw] text-[1.3vw] max-sm:text-[3vw]">
                            {searchResults.length} Results
                        </p>
                        <div className="flex justify-center flex-wrap  max-sm:flex-col">
                            {searchResults &&
                                searchResults.map((blog, index) => {
                                    return (
                                        <div className="w-[33%] max-sm:w-[100%]">
                                            <BlogCard key={index} blog={blog} />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                ) : categoryList ? (
                    <>
                        {category === "All" && (
                            <div className="py-4 px-[8vw]">{getLatestBlogs()}</div>
                        )}
                        {categoryList.map((data, index) => (
                            <div className="py-4 px-[8vw]">
                                <div className="flex justify-between items-center mx-[1vw]">
                                    <p className="text-[1.5vw] font-PoppinsSemiBold pb-[1vw] max-sm:text-[4vw]">
                                        {data.tag_name}
                                    </p>
                                    {category === "All" && (
                                        <a
                                            className="text-[#3940A0] font-PoppinsMedium text-[0.9vw] max-sm:text-[2.5vw]"
                                            // href={`blogs/${Buffer.from(data.tag_name).toString(
                                            //     "base64"
                                            // )}`}
                                            passHref
                                            onClick={() => handleCategoryChange(data.tag_name)}
                                        >
                                            See All
                                        </a>
                                    )}
                                </div>
                                <div className="flex justify-evenly flex-wrap max-sm:flex-col">
                                    {blogs &&
                                        blogs.has(data.tag_name) &&
                                        blogs.get(data.tag_name).map((blog, index) => {
                                            return (
                                                <div className="w-[33%] max-sm:w-full max-sm:my-[2vw]">
                                                    <BlogCard key={index} blog={blog} />
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="container">
                        <CircularProgress /> &nbsp; Loading...
                    </div>
                )}
            </div>
            <NewsLetter />
            {category !== "All" && <ExploreBlogs />}

            <ExploreCourses />
        </div>
    );
};

export default BlogsHomePage;
