const TAG_LIST = [
    {
        tag_name: "Data Structures & Algorithms",
    },
    {
        tag_name: "Interview Preparation",
    },
    {
        tag_name: "Competitive Programming",
    },
    {
        tag_name: "Student Experiences",
    },
    {
        tag_name: "About Us",
    },
    {
        tag_name: "Time and Space Complexity",
    },
];

const CATEGORIES_FIRST_ROW = [
    {
        tag_name: "All",
    },
    {
        tag_name: "Data Structures & Algorithms",
    },
    {
        tag_name: "Interview Preparation",
    },
    {
        tag_name: "Competitive Programming",
    },
];

const CATEGORIES_SECOND_ROW = [
    {
        tag_name: "Student Experiences",
    },
    {
        tag_name: "About Us",
    },
    {
        tag_name: "Time and Space Complexity",
    },
];

const ShareMessageContent = {
    MSG_TITLE: "Hey there! Read this interesting blog by Programming Pathshala ",
};

export { ShareMessageContent };
const ONE_YEAR_IN_SECONDS = 31536000;
const COOKIE_MAX_AGE = ONE_YEAR_IN_SECONDS;
export { CATEGORIES_FIRST_ROW, CATEGORIES_SECOND_ROW, COOKIE_MAX_AGE, TAG_LIST };
