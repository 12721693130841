import axios from "axios";
import Cookies from "universal-cookie";
const axiosInstance = axios.create();
let postQueryForm = (details, setSnackBarOpen, setSnackBarClose) => {
    const cookies = new Cookies();
    let {
        title,
        urlPath,
        authorName,
        description,
        readTime,
        startDate,
        updatedDate,
        largeImage,
        mediumImage,
        smallImage,
        tagName,
        youtubeLink,
    } = details;

    let data = {
        title: title,
        urlPath: urlPath,
        description: description,
        author: authorName,
        read_time: readTime,
        date_created: startDate,
        date_updated: updatedDate,
        banner_image_url: largeImage,
        preview_image_url: mediumImage,
        most_viewed_blog_image_url: smallImage,
        tags: tagName,
        youtubeLink: youtubeLink,
    };
    axiosInstance
        .post(
            `${process.env.REACT_APP_MISCELLANEOUS_URL}/postBlogs`,

            data,
            {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            }
        )
        .then((response) => {
            setSnackBarOpen(true);
            if (response.status === 200) {
                console.log("blogs posting is successful");
            }
        })
        .catch((e) => {
            setSnackBarClose(true);
        });
};

let updateBlogs = (details, id, setSnackBarOpen, setSnackBarClose) => {
    const cookies = new Cookies();
    let {
        title,
        urlPath,
        authorName,
        description,
        readTime,
        largeImage,
        mediumImage,
        smallImage,
        youtubeLink,
    } = details;

    let data = {
        id: id,
        title: title,
        urlPath: urlPath,
        description: description,
        author: authorName,
        read_time: readTime,
        banner_image_url: largeImage,
        preview_image_url: mediumImage,
        most_viewed_blog_image_url: smallImage,
        youtubeLink: youtubeLink,
    };
    axiosInstance
        .post(`${process.env.REACT_APP_MISCELLANEOUS_URL}/updateBlogs`, data, {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            setSnackBarOpen(true);
        })
        .catch((e) => {
            setSnackBarClose(true);
        });
};

let uploadBlogImageToCloud = (data, setImageLink, setLoader, setSnackBarClose) => {
    const cookies = new Cookies();
    axiosInstance
        .post(process.env.REACT_APP_MISCELLANEOUS_URL + `/uploadBlogImageToCloud`, data, {
            headers: {
                authorization: cookies.get("authorization"),
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            setImageLink(response.data.imageURL);
            setLoader(false);
        })
        .catch((error) => {
            setLoader(false);
            setSnackBarClose(true);
        });
};

let deleteBlogImageFromCloud = (
    imageLink,
    imageType,
    setImageLink,
    setLoader,
    setSnackBarClose
) => {
    setLoader(true);
    const cookies = new Cookies();
    const data = {
        imageURL: imageLink,
        type: imageType,
    };
    axiosInstance
        .post(process.env.REACT_APP_MISCELLANEOUS_URL + `/deleteBlogImageFromCloud`, data, {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            setImageLink("");
            setLoader(false);
        })
        .catch((error) => {
            setSnackBarClose(true);
            setLoader(false);
        });
};

let getAuthorList = (setAuthorArray) => {
    axios
        .get(`${process.env.REACT_APP_MISCELLANEOUS_URL}/getAuthorsList`)
        .then((response) => {
            setAuthorArray(response.data.authorList);
        })
        .catch((error) => {
            console.error(error);
        });
};

let getTagsList = (setTagList) => {
    axios
        .get(`${process.env.REACT_APP_MISCELLANEOUS_URL}/getTagsList`)
        .then((response) => {
            setTagList(response.data.tagsList);
        })
        .catch((error) => {
            console.error(error);
        });
};

const getBlogById = (id, setBlogDetails, setTagsList, setShowError) => {
    const cookies = new Cookies();
    axios
        .get(process.env.REACT_APP_MISCELLANEOUS_URL + `/blogs/${id}`, {
            headers: {
                authorization: cookies.get("authorization"),
            },
        })
        .then((response) => {
            setBlogDetails(response.data.blog[0]);
            setTagsList(response.data.blog[0].tags);
        })
        .catch((e) => {
            setShowError(true);
        });
};

export {
    getTagsList,
    getAuthorList,
    postQueryForm,
    uploadBlogImageToCloud,
    deleteBlogImageFromCloud,
    updateBlogs,
    getBlogById,
};
