import { useState } from "react";
import { postNewsLetterLeads } from "./NewsLetterClient";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/Snackbar";
import CloseIcon from "@mui/material/Snackbar";

const NewsLetter = () => {
    const [email, setEmail] = useState("");
    const [snackbarOpen, setSnackBarOpen] = useState(false);
    const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("");
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    return (
        <div className="bg-[#E8EDFB] flex flex-col items-center justify-between py-[3vw] my-[5vw]">
            <p className="font-PoppinsSemiBold text-[2vw] max-sm:text-[5vw]">Join our Newsletter</p>
            <p className="font-PoppinsRegular font-normal  text-[1vw] w-[25vw] py-[1vw] max-sm:text-[2.5vw] max-sm:w-[60%] max-sm:text-center max-sm:pb-[4vw]">
                Get exclusive content and become a part of the Code Everyday community
            </p>
            <input
                className="border border-[#D0D5DD] focus:outline-none rounded-[6px] text-[1vw] text-[#191D23] p-[1vw] w-[25vw] bg-[#F7F8F9] font-PoppinsRegular font-normal max-sm:w-[60%] max-sm:mx-[4vw] max-sm:p-[2vw] max-sm:text-[3vw]"
                type="text"
                placeholder="Your Email Address"
                onChange={handleEmailChange}
            />
            <div
                className="py-[1vw] w-[25vw] max-sm:w-[60%]  max-sm:mt-[2vw] max-sm:mb-[0.5vw]"
                onClick={() => {
                    postNewsLetterLeads(email, setSnackBarOpen, setSnackbarErrorMessage);
                }}
            >
                <button className="w-full bg-[#3940A0] text-[#ffffff] font-PoppinsRegular font-medium text-[1.2vw] p-[1vw] rounded-[6px] max-sm:text-[2.5vw] max-sm:p-[2vw]">
                    Subscribe Now
                </button>
            </div>
            <p className="text-[#191D23] text-[0.95vw] font-medium max-sm:text-[2.5vw]">
                Spam free and unsubscribe anytime.
            </p>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleErrorSnackbarClose}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleErrorSnackbarClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                message={snackbarErrorMessage}
            />
        </div>
    );
};

export default NewsLetter;
