import renImage from "../../resources/images/ren-banner.png";
import lldConcurrencyImage from "../../resources/images/lld-concurrency-banner.png";
import pythonImage from "../../resources/images/python-banner.svg";
import cppImage from "../../resources/images/cpp-banner.svg";
import dsaEssentialsImage from "../../resources/images/dsa-essentials-banner.png";
import { availableCourses } from "./ExploreCoursesConstants";

const courseCardDetails = [
    {
        courseBannerImg: renImage,
        courseDescription:
            "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}crack-coding-interviews`,
        clickable: true,
        courseName: availableCourses.RENAISSANCE,
    },
    {
        courseBannerImg: lldConcurrencyImage,
        courseDescription:
            "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}low-level-design`,
        clickable: true,
        courseName: availableCourses.LLD,
    },
    {
        courseBannerImg: dsaEssentialsImage,
        courseDescription:
            "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}dsa-and-algorithms-essentials`,
        clickable: true,
        courseName: availableCourses.CRASH_COURSE,
    },
    {
        courseBannerImg: cppImage,
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-c++`,
        clickable: true,
        courseName: availableCourses.CPP,
    },

    {
        courseBannerImg: pythonImage,
        courseDescription:
            "Become a Python pro and unlock the door to endless career opportunities",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-python`,
        clickable: true,
        ctaButton: "Know More",
        courseName: availableCourses.PYTHON,
    },
];
export { courseCardDetails };
