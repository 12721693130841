import React from "react";
import NavLinks from "./NavLinks";
import logo from "../../resources/images/PpaLogo.svg";
import HeaderForMob from "./HeaderForMob";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../context/GlobalContext";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Header = (props) => {
    const query = useQuery();
    const navigate = useNavigate();
    const [buttonName, setButtonName] = useState("Sign Up");
    const { loggedIn, initiateLogout } = useGlobalContext();

    const { windowWidth } = useWindowDimensions();
    const listenToScroll = () => {
        let height = 400;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setButtonName(winScroll);

        if (height >= winScroll) {
            setButtonName("Sign Up");
        } else {
            setButtonName("Start 7 Day Free Trial");
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    const getHeaderforDesktop = () => {
        return (
            <div className="flex justify-between items-center px-[0.5vw]">
                <div>
                    <a target="_blank" rel="noreferrer" href="https://programmingpathshala.com/">
                        <img className="w-[16vw] h-[3vw]" src={logo} alt="" />
                    </a>
                </div>

                <NavLinks />

                <div>{getHeaderBtnForDeskTop()}</div>
            </div>
        );
    };

    const getHeaderBtnForDeskTop = () => {
        if (!loggedIn) {
            return (
                <div className="flex items-center">
                    <div
                        onClick={() => {
                            window.open("https://renaissance.programmingpathshala.com/login");
                        }}
                    >
                        <p className="font-PoppinsSemiBold text-[1.3vw] text-blueTextColor cursor-pointer whitespace-nowrap mr-[1vw]">
                            Log In
                        </p>
                    </div>
                    <div
                        onClick={() => {
                            window.open("https://renaissance.programmingpathshala.com/signup");
                        }}
                    >
                        <button className="text-white text-[1.2vw] font-PoppinsSemiBold p-[0.75vw] rounded-md bg-[linear-gradient(93.53deg,#444bab_0%,#416bb3_62.5%,#3ca5c2_100%)]">
                            {buttonName}
                        </button>
                    </div>
                </div>
            );
        } else {
            return (
                <div onClick={() => initiateLogout()}>
                    <p className="font-PoppinsSemiBold text-[1.3vw] text-blueTextColor cursor-pointer whitespace-nowrap">
                        Log Out
                    </p>
                </div>
            );
        }
    };

    return (
        <div
            id="header"
            className="sticky z-50 top-0 px-[0.5vw] py-[1vw] bg-white shadow-headerBoxShadow 
        max-sm:flex max-sm:justify-between max-sm:items-center max-sm:px-[1.5vw] max-sm:py-[2vw]"
        >
            {windowWidth > 600 ? getHeaderforDesktop() : <HeaderForMob />}

            {windowWidth <= 600 ? (
                <>
                    <div>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                        >
                            <img src={logo} alt="ppa-logo" width={220} height={35} />
                        </a>
                    </div>
                    <div
                        onClick={() =>
                            window.open(
                                "https://renaissance.programmingpathshala.com/signup",
                                "_self"
                            )
                        }
                    ></div>
                    <div onClick={() => (!loggedIn ? navigate("/login") : initiateLogout())}>
                        {!loggedIn ? (
                            <p className="font-PoppinsSemiBold text-lg text-blueTextColor whitespace-nowrap cursor-pointer">
                                Log In
                            </p>
                        ) : (
                            <p className="font-PoppinsSemiBold text-lg text-blueTextColor whitespace-nowrap cursor-pointer">
                                Log Out
                            </p>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Header;
