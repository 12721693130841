import React from "react";
import MDEditor from "@uiw/react-md-editor";
import "@uiw/react-md-editor/markdown-editor.css";
import "@uiw/react-markdown-preview/markdown.css";
import "@mdxeditor/editor/style.css";

const MarkDownEditor = (props) => {
    const { setDescription, description } = props;
    const [value, setValue] = React.useState("");

    return (
        <div>
            <MDEditor height={900} value={description} onChange={setDescription} />
        </div>
    );
};

export default MarkDownEditor;
