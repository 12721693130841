import React from "react";
import HeaderCoursesDropDown from "./HeaderCoursesDropDown";

const NavLinks = (props) => {
    return (
        <div className="flex justify-between items-center pl-[4vw] px-[2vw]">
            <HeaderCoursesDropDown />

            <a
                className="text-greyTextColor font-GilroyMedium text-[1vw] mx-[0.9vw]"
                target="_blank"
                rel="noreferrer"
                href="https://renaissance.programmingpathshala.com/crack-coding-interviews/stories"
            >
                Success Stories
            </a>

            <a
                className="text-greyTextColor font-GilroyMedium text-[1vw] mx-[0.9vw]"
                target="_blank"
                rel="noreferrer"
                href="https://programmingpathshala.com/hire-from-us#"
            >
                Hire From Us
            </a>

            <a
                className="text-greyTextColor font-GilroyMedium text-[1vw] mx-[0.9vw]"
                target="_blank"
                rel="noreferrer"
                href="https://programmingpathshala.com/campus-program#"
            >
                Campus Program
            </a>

            <a
                className="text-greyTextColor font-GilroyMedium text-[1vw] mx-[0.9vw]"
                target="_blank"
                rel="noreferrer"
                href="https://blogs.programmingpathshala.com/"
            >
                Blogs
            </a>

            <a
                className="text-greyTextColor font-GilroyMedium text-[1vw] mx-[0.9vw]"
                target="_blank"
                rel="noreferrer"
                href="https://events.programmingpathshala.com/"
            >
                Events
            </a>
        </div>
    );
};

export default NavLinks;
