import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { coursesDetails } from "./CourseListConstants";
import newSticker from "../../resources/images/NewSticker.svg";

export default function HeaderCoursesDropDown() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    textTransform: "none",
                    color: "#4f516b",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "1vw",
                    whiteSpace: "noWrap",
                    letterSpacing: 0,
                    "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                    },
                }}
            >
                Explore Courses <ExpandMoreIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {coursesDetails &&
                    coursesDetails.map((data, index) => (
                        <MenuItem
                            divider={true}
                            key={index}
                            className="flex w-[39vw] max-sm:w-[65vw]"
                            onClick={handleClose}
                        >
                            <a
                                href={data.clickable && data.courseLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="flex items-center">
                                    <p className="text-[#3940a0] text-[1vw] font-PoppinsSemiBold">
                                        {data.courseName}
                                    </p>
                                    {data.showNewIcon && (
                                        <img
                                            className="relative h-[1.5vw] w-[1.5vw] ml-1 "
                                            src={newSticker}
                                            alt=""
                                        />
                                    )}
                                </div>

                                <p className="text-[#888888] text-[0.9vw] font-PoppinsRegular pt-[0.5vw] pb-[0.5vw] whitespace-break-spaces">
                                    {data.courseDescription}
                                </p>
                            </a>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}
