import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import DehazeIcon from "@mui/icons-material/Dehaze";
import logo from "../../resources/images/PpaLogo.svg";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { coursesDetails } from "./CourseListConstants";
import showMoreArrow from "../../resources/images/showMoreArrow.png";
import newSticker from "../../resources/images/NewSticker.svg";
import { courses } from "./CourseListConstants";

export default function TemporaryDrawer() {
    const navigate = useNavigate();
    const { loggedIn, initiateLogout } = useGlobalContext();

    const [showDropDown, setShowDropDown] = useState(false);

    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const getButtons = () => {
        return (
            <>
                {loggedIn ? (
                    <button
                        className="bg-blueGradient text-white font-semibold rounded-[2px] p-[1px]"
                        onClick={() => {
                            initiateLogout();
                        }}
                    >
                        <span class="flex w-full bg-white text-blueTextColor rounded-[2px] px-[2vw] py-[1vw]">
                            Log Out
                        </span>
                    </button>
                ) : (
                    <button
                        onClick={() => {
                            window.open("https://renaissance.programmingpathshala.com/login");
                        }}
                        className="bg-blueGradient text-white font-semibold rounded-[2px] p-[1px]"
                    >
                        <span class="flex w-full bg-white text-blueTextColor rounded-[2px] px-[2vw] py-[1vw]">
                            Log In
                        </span>
                    </button>
                )}
            </>
        );
    };

    const getDropdownForMob = () => {
        return (
            <div className="px-[3vw]">
                {coursesDetails &&
                    coursesDetails.map((data, index) => (
                        <div
                            className="py-[1vw]"
                            onClick={() => {
                                data.clickable && window.open(data.courseLink);
                            }}
                            key={index}
                        >
                            <div className="flex items-center ">
                                <p className="text-[#3940a0] text-[2.7vw] font-PoppinsSemiBold">
                                    {data.courseName}
                                </p>
                                {data.courseName === courses.RENAISSANCE ? (
                                    <></>
                                ) : (
                                    <img
                                        className="relative h-[2.5vw] w-[2.5vw] ml-1 "
                                        src={newSticker}
                                        alt=""
                                    />
                                )}
                            </div>

                            <p className="text-[#191d23] text-[2.5vw] font-PoppinsRegular pt-[0.5vw] pb-[0.5vw] whitespace-break-spaces">
                                {data.courseDescription}
                            </p>
                        </div>
                    ))}
            </div>
        );
    };

    const NavigationList = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="pl-[4vw] pt-[4vw]">
                <a target="_blank" rel="noreferrer" href="https://programmingpathshala.com/">
                    <img className="my-[4vw] w-[75%]" src={logo} alt="PPA-logo" />
                </a>
            </div>
            <div className="font-GilroyMedium pl-2 text-greyTextColor flex flex-col">
                <div
                    className="flex items-center text-greyTextColor font-GilroyMedium pl-[3vw] pb-[2vw]"
                    onClick={() => setShowDropDown(!showDropDown)}
                >
                    Explore Courses <img className="w-[2.5vw] h-[1.5vw] ml-2" src={showMoreArrow} />
                </div>
                {showDropDown && getDropdownForMob()}

                <a
                    className="pl-4 pb-4"
                    href="https://renaissance.programmingpathshala.com/crack-coding-interviews/stories"
                >
                    Success Stories
                </a>
                <a className="pl-4 pb-4" href="https://programmingpathshala.com/hire-from-us#">
                    Hire With Us
                </a>
                <a className="pl-4 pb-4" href="https://programmingpathshala.com/campus-program#">
                    Campus Program
                </a>
                <a className="pl-4 pb-4" href="https://blogs.programmingpathshala.com/">
                    Blogs
                </a>
                <a className="pl-4 pb-4" href="https://events.programmingpathshala.com/">
                    Events
                </a>
            </div>
            <div className="flex justify-center">{getButtons()}</div>
        </Box>
    );

    return (
        <div>
            {["left"].map((anchor, index) => (
                <React.Fragment key={index}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <DehazeIcon sx={{ color: "#333" }} />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {NavigationList(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
